import { Flex, Heading, Text, Button, useBreakpointValue, Link } from '@chakra-ui/react'
import NextImage from 'next/image'

import { s3ImageURL } from '@miimosa/common'

const Mission = () => {
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: 'lg' })

  return (
    <Flex
      id="program"
      w="full"
      h="full"
      py={10}
      bg="white"
      direction={{ base: 'column-reverse', md: 'row' }}
      px={16}
      columnGap={16}
      rowGap={16}
    >
      <Flex direction="column" h="full" w="full" maxW="500px" flexShrink={2}>
        <Flex h="405px" position="relative" direction="column" alignItems="center">
          <Flex
            w="full"
            h="full"
            bg="yellow"
            transform={'translateX(-20px) translateY(-20px)'}
            borderTopLeftRadius="20px"
          />
          <NextImage
            src={s3ImageURL('field.png')}
            alt={'field'}
            fill
            style={{
              objectFit: 'cover',
              borderTopLeftRadius: '20px',
              borderBottomRightRadius: '20px',
            }}
            sizes={'(max-width: 1000px) 50vw, 33vw'}
          />
        </Flex>
        {isMobile && (
          <>
            <Text color="black" textAlign="justify" size="lg" my={6}>
              Le but de cet appel à projet est de soutenir et d&apos;accompagner les producteurs de pommes de terre dans
              leur projet de transition agricole et alimentaire. Mousline s&apos;engage concrètement avec une enveloppe
              de 60 000€ de dotations financières pour sa filière.
            </Text>
            <Link href="#form">
              <Button bg="yellow" w="fit-content">
                <Text color="black" size="lg">
                  Je candidate
                </Text>
              </Button>
            </Link>
          </>
        )}
      </Flex>
      <Flex direction="column" justifyContent="center" maxW="500px">
        <Heading color="red" size="lg">
          La mission de Mousline :
        </Heading>
        <Heading color="black" size="md">
          nourrir le plus grand nombre, de manière saine et accessible.
        </Heading>
        {!isMobile && (
          <>
            <Text color="black" textAlign="justify" size="lg" my={6}>
              Le but de cet appel à projet est de soutenir et d&apos;accompagner les producteurs de pommes de terre dans
              leur projet de transition agricole et alimentaire. Mousline s&apos;engage concrètement avec une enveloppe
              de 60 000€ de dotations financières pour sa filière.
            </Text>
            <Link href="#form">
              <Button bg="yellow" w="fit-content">
                <Text color="black" size="lg">
                  Je candidate
                </Text>
              </Button>
            </Link>
          </>
        )}
      </Flex>
    </Flex>
  )
}

export default Mission
